<template>
  <div class="Sign login-sign">
    <!-- <div class="num">学号/工号：</div>
    <input v-model="username" type="math" class="sign-ipt" placeholder="请输入学号/工号" />
    <div class="num">邮箱：</div>
    <input v-model="email" type="email" class="sign-ipt" placeholder="请输入邮箱" />
    <div class="num">姓名：</div>
    <input v-model="nick" type="name" class="sign-ipt" placeholder="请输入姓名" />
    <div class="num">电话：</div>
    <input v-model="phone" type="phone" class="sign-ipt" placeholder="请输入电话" />
    <div class="num">密码：</div>
    <input
      v-model="password"
      type="password"
      class="sign-ipt"
      placeholder="请输入密码(大于六位数字)" />
    <div class="num">确认密码：</div>
    <input
      v-model="repassword"
      type="password"
      class="sign-ipt"
      placeholder="请确认密码(大于六位数字)" /> -->
    <el-form label-position="right" label-width="80px" :model="signForm">
      <el-form-item label="学号/工号">
        <el-input v-model="signForm.username" placeholder="请输入学号/工号"></el-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="signForm.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="signForm.nick" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input v-model="signForm.phone" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="signForm.password" placeholder="请输入密码(大于六位数字)" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input v-model="signForm.repassword" placeholder="请输入学号/工号" show-password></el-input>
      </el-form-item>
    </el-form>
    <input @click="Sign" type="button" class="sign-btn" value="注册" />
  </div>
</template>

<script>
import verifyMail from '../../static/code';
import {sign} from '../../service/getData';
import chinese from '../../utils/chinese';
export default {
  name: 'SignPage',
  data() {
    return {
      signForm: {
        email: '',
        nick: '',
        phone: '',
        password: '',
        repassword: '',
        studentId: ''
      }
    };
  },
  methods: {
    //注册
    Sign() {
      let {email, password, repassword, nick, phone, username} = this.signForm;
      //邮箱、密码、确认密码、验证码不能为空
      if (
        email !== '' &&
        password !== '' &&
        repassword !== '' &&
        nick !== '' &&
        phone !== '' &&
        username !== ''
      ) {
        if (chinese.isChn(nick)) {
          //两次密码是否相同
          if (verifyMail(email)) {
            let reg = /^1[3456789]\d{9}$/;
            if (reg.test(phone)) {
              if (password.trim().length > 6) {
                if (password === repassword) {
                  sign(
                    repassword,
                    email,
                    nick,
                    password,
                    phone,
                    username
                  ).then(
                    (values) => {
                      console.log(values);
                      if (values.code === 200) {
                        this.$message({
                          type: 'success',
                          message: values.message
                        });
                        let _this = this;
                        setTimeout(() => {
                          _this.$router.push({
                            name: 'LoginPage'
                          });
                        }, 1000);
                      } else {
                        this.$message({
                          type: 'error',
                          message: values.message
                        });
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
                } else {
                  this.$message('两次密码输入不正确');
                }
              } else {
                this.$message('密码长度大于应六位数');
              }
            } else {
              this.$message('请输入正确的电话号码');
            }
          } else {
            this.$message('请输入正确的邮箱格式');
          }
        } else {
          this.$message('姓名不合法');
        }
      } else {
        this.$message('请填写完整信息');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.num {
  margin-top: 10px;
  height: 20px;
  font-size: 14px;
}
.sign-ipt {
  margin: 0 !important;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border: 0;
  border-bottom: 1px solid #777575;
  outline: none;
}
.login-sign {
  padding: 20px;
  width: 100%;
  .sign-ipt {
    margin-top: 20px;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    border: 0;
    border-bottom: 1px solid #777575;
    outline: none;
  }
  .send-btn:active {
    color: #000000;
    background-color: #d4d1d1;
  }
  .sign-btn {
    position: relative;
    left: 50%;
    margin-top: 10px !important;
    width: 50%;
    height: 40px;
    border: 0;
    color: #ffffff;
    background-color: #23a9f2;
    outline: none;
    cursor: pointer;
    transform: translateX(-50%);
  }
  .sign-btn:active {
    color: #000000;
    background-color: #d4d1d1;
  }
}
</style>